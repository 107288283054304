.background-detroit{
  background-position: center;
  background-size: cover;
  background-image: url('../img/detroit.png');
}

.redlineLogo{
  background-color: #000;
}

.homeInfo{
  background-color: rgba(0,0,0,0.6);
  padding: 20px;
  border-radius: 20px;
}

.linkItem{
  margin: 25px 0px;
}

.customServiceHeaderImage{
  background-color: #5e72e4;
  color: #fff;
  padding:25px;
  margin-top:-100px;
  text-align: center;
  font-size: 150px;
}

.centerHeaderImage{
  display:table;
  margin:auto;
}

.greenHeaderImage{
  background: linear-gradient(35deg, #2dce89 0, #2dcecc 100%) !important;
}

.orangeHeaderImage{
  background: linear-gradient(35deg, #fb6340 0, #fbb140 100%) !important;
}

.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 220px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  // background-color: #111; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 0px;
}

.mainAuth{
  margin-left: 220px !important;
}

.topNavAuth{
  margin-top:10px;
  padding-top:15px;
  padding-left:10px;
  padding-right:10px;
  margin-bottom:20px;
}

.topAuthDate{
  text-align:right;
}

.authFooter{
  margin-left: 220px !important;
}

.authContainer{
  padding-left: 10px;
  padding-right:10px;
  padding-bottom:40px;
}

.btnNav{
  width:100%;
  margin-bottom:15px;
}
.rowMargin{
  margin-top:20px;
}
